import { useEffect } from "react";

const mainDiv = {
  margin: "-8px",
  fontFamily: "sans-serif",
};

const Terms = () => {
  useEffect(() => {
    // Check if we're not already on the app.moongig.xyz/terms page
    console.log("TERMS", window.location.hostname);
    if (window.location.hostname !== "app.moongig.xyz") {
      window.location.href = "https://app.moongig.xyz/terms";
    }
  }, []);

  return <div style={mainDiv}></div>;
};

export default Terms;
