import { isMobile } from "react-device-detect";
import HeaderMobile from "./mobile/Header";
import FooterMobile from "./mobile/Footer";
import FooterDesktop from "./desktop/Footer";
import HeaderDesktop from "./desktop/Header";
import { Helmet } from "react-helmet";

const canonicalUrl = "https://moongig.xyz/terms";

const mainDiv = {
  margin: "-8px",
  fontFamily: "sans-serif",
};

var headerFontSize = "48px";
var contentFontSize = "20px";
var sectionDiv = {
  padding: "40px 100px",
};

if (isMobile) {
  headerFontSize = "36px";
  contentFontSize = "16px";
  sectionDiv = {
    padding: "20px",
  };
}

const TermsOfService = () => {
  return (
    <div style={mainDiv}>
      {/* helmet */}
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      {/* banner div */}
      {isMobile ? <HeaderMobile /> : <HeaderDesktop />}
      <div style={sectionDiv}>
        <p style={{ fontSize: headerFontSize, fontWeight: "bold" }}>
          Terms of Service
        </p>
        <div style={{ lineHeight: "30px" }}>
          <p style={{ fontSize: contentFontSize }}>Welcome to Moongig:</p>
          <p style={{ fontSize: contentFontSize }}>
            These Terms and Conditions govern your use of Moongig, an online
            platform connecting healthcare professionals and healthcare
            providers. By accessing or using Moongig, you agree to be bound by
            these terms.
          </p>
          <p style={{ fontSize: contentFontSize, paddingTop: "10px" }}>
            <ul style={{ listStyle: "none" }}>
              <li>
                1. User Accounts: Users must create an account and provide
                accurate information. Moongig reserves the right to suspend or
                terminate accounts for misinformation.
              </li>
              <br />
              <li>
                2. Services Offered: Moongig provides a platform for healthcare
                professionals to find temporary positions. Moongig is not
                responsible for the contracts or agreements made between users.
              </li>
              <br />
              <li>
                3. User Conduct: Users must conduct themselves professionally
                and ethically. Moongig reserves the right to remove users who
                engage in inappropriate or illegal activities.
              </li>
              <br />
              <li>
                4. Intellectual Property: All content on Moongig is owned by or
                licensed to us and is protected by copyright and trademark laws.
                Users may not use the content without our prior written
                permission.
              </li>
              <br />
              <li>
                5. Liability and Indemnification: Users agree to indemnify
                Moongig against all liabilities, costs, and expenses arising
                from any breach of these terms.
              </li>
              <br />
              <li>
                6. Dispute Resolution: Any disputes will be resolved through
                binding arbitration in accordance with the rules of the American
                Arbitration Association.
              </li>
              <br />
              <li>
                7. Amendments to Terms and Conditions: Moongig reserves the
                right to amend these terms at any time. Users will be notified
                of any changes.
              </li>
              <br />
              <li>
                8. Governing Law: These terms are governed by the laws of the
                jurisdiction where Moongig is headquartered.
              </li>
            </ul>
          </p>
          <br />
          <p style={{ fontSize: contentFontSize }}>Contact Us:</p>
          <p style={{ fontSize: contentFontSize }}>
            If you have any questions about these Terms and Conditions, please
            contact us at{" "}
            <a href="mailto:support@moongig.xyz">support@moongig.xyz</a>.
          </p>
          <br />
          <p style={{ fontSize: contentFontSize }}>
            Thank you for choosing Moongig.
          </p>
        </div>
      </div>
      {/* contact div */}

      {isMobile ? <FooterMobile /> : <FooterDesktop />}
    </div>
  );
};

export default TermsOfService;
