import { isMobile } from "react-device-detect";
import logo from "../static/logo.svg";

// import ellipse from "../static/professional/ellipse.webp";
// import ellipsemobile from "../static/professional/ellipse-mobile.png";

// import background from "../static/provider/background.png";
import headerimage1 from "../static/provider/header-image1.webp";
import sectionimage1 from "../static/provider/section-image1.webp";
import sectionimage2 from "../static/provider/section-image2.webp";
import sectionimage3 from "../static/provider/section-image3.webp";
//hq svg images
import headerimage1hq from "../static/provider/header-image1.svg";
import sectionimage1hq from "../static/provider/section-image1.svg";
import sectionimage2hq from "../static/provider/section-image2.svg";
import sectionimage3hq from "../static/provider/section-image3.svg";
import arrowright from "../static/provider/arrow-right.svg";
import { useState, useEffect, useRef } from "react";
import axios from "axios";

import FooterMobile from "./mobile/Footer";
import FooterDesktop from "./desktop/Footer";
import { Helmet } from "react-helmet";

const canonicalUrl = "https://moongig.xyz/provider";

const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const API_SECRET = process.env.REACT_APP_MOONGIG_SECRET;

// styles
const styleXL = {
  logoImg: {
    height: "70px",
    width: "240px",
    zIndex: 1,
  },
  mainDiv: {
    margin: "-8px",
    fontFamily: "sans-serif",
  },
  bannerDiv: {
    padding: "70px 100px",
    backgroundImage: "linear-gradient(90deg, #31795A 0%, #23CE85 100%)",
    backgroundSize: "cover",
  },
  ellipse: {
    position: "absolute",
    marginTop: "-220px",
    marginLeft: "-100px",
    zIndex: 0,
    height: "500px",
    width: "900px",
    background:
      "radial-gradient(ellipse at left top, rgba(0, 0, 0, 0.25) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0))",
  },
  headerButtonCont: {
    display: "inline-block",
    flexGrow: 1,
    textAlign: "right",
    padding: "6px",
    zIndex: 1,
  },
  headerButton1: {
    padding: "16px 32px",
    backgroundColor: "transparent",
    border: 0,
    borderRadius: 8,
    fontSize: "17px",
    fontWeight: "bold",
    color: "#FFF",
  },
  headerButton2: {
    padding: "16px 32px",
    backgroundColor: "#23CE85",
    marginLeft: "12px",
    border: 0,
    borderRadius: 8,
    fontSize: "17px",
    fontWeight: "bold",
    color: "#161C2D",
  },
  bannerFlex: {
    display: "flex",
    paddingTop: "70px",
    zIndex: 1,
  },
  bannerFlexCont1: {
    width: "50%",
    textAlign: "left",
    paddingRight: "40px",
    zIndex: 1,
  },
  bannerFlexContText1: {
    fontSize: "48px",
    fontWeight: "bold",
    color: "#FFF",
    paddingTop: "40px",
    lineHeight: "64px",
  },
  bannerFlexContText2: {
    paddingTop: "40px",
    fontSize: "32px",
    color: "rgba(255, 255, 255, 0.75)",
    lineHeight: "50px",
    fontWeight: "400",
    paddingRight: "10px",
  },
  bannerFlexContText3: {
    marginTop: "60px",
    fontSize: "28px",
    color: "#9BFFFF",
    lineHeight: "50px",
    fontWeight: "bold",
    paddingRight: "10px",
  },
  bannerFlexContText3Arrow: {
    width: "20px",
    marginLeft: "20px",
  },
  bannerFlexCont2: {
    flexGrow: 1,
    paddingLeft: "40px",
  },

  sectionDiv: {
    padding: "40px 100px",
  },
  sectionSubDiv: {
    display: "flex",
    paddingTop: "70px",
  },
  sectionSubDivReversed: {
    display: "flex",
    paddingTop: "70px",
    flexDirection: "row-reverse",
  },
  sectionSubDivContTable: {
    width: "50%",
    textAlign: "left",
    paddingRight: "40px",
    display: "table",
  },
  sectionSubDivContCell: {
    display: "table-cell",
    verticalAlign: "middle",
  },
  sectionSubDivContHeader: {
    fontSize: "48px",
    fontWeight: "bold",
    color: "#161C2D",
    lineHeight: "64px",
  },
  sectionSubDivContText: {
    paddingTop: "40px",
    fontSize: "32px",
    color: "rgba(0, 0, 0, 0.75)",
    lineHeight: "50px",
    fontWeight: "400",
    paddingRight: "10px",
  },
  sectionSubDivImage: {
    flexGrow: 1,
    paddingLeft: "40px",
  },

  signupDiv: {
    padding: "80px 100px",
    backgroundColor: "#EFF3FB",
  },

  signupDivCont: {
    display: "flex",
    padding: "36px 100px",
    backgroundColor: "#161C2D",
    borderRadius: "16px",
  },
  signupDivCont1: {
    width: "48%",
    textAlign: "left",
    paddingRight: "40px",
  },
  signupDivContCell: {
    display: "table-cell",
    verticalAlign: "middle",
  },
  singupDivContText: {
    fontSize: "32px",
    fontWeight: "bold",
    color: "#48D89A",
    lineHeight: "48px",
  },
  signupDivContTable2: {
    flexGrow: 1,
    paddingLeft: "40px",
    display: "table",
    paddingBottom: "20px",
  },
  signupDivButton: {
    padding: "16px 32px",
    backgroundColor: "#23CE85",
    border: 0,
    borderRadius: "8px",
    fontSize: "17px",
    fontWeight: "bold",
    color: "#111928",
    marginTop: "20px",
  },

  signupDivButton2: {
    padding: "16px 32px",
    backgroundColor: "#FFF",
    border: 0,
    borderRadius: "8px",
    fontSize: "17px",
    fontWeight: "bold",
    color: "#111928",
    marginTop: "40px",
  },
  signupDivFlexReversed: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  signupDivInput: {
    height: "40px",
    borderRadius: "8px",
    border: "1px",
    width: "100%",
    paddingLeft: "16px",
    paddingTop: "0px",
    paddingBottom: "0px",
    marginTop: "10px",
    marginBottom: "20px",
  },

  signupDivInputError: {
    height: "40px",
    borderRadius: "8px",
    border: "1px solid red",
    width: "100%",
    paddingLeft: "16px",
    paddingTop: "0px",
    paddingBottom: "0px",
    marginBottom: "20px",
    marginTop: "10px",
    backgroundColor: "#ffdddd",
  },
  signupDivInputLabel: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "#FFF",
  },
  signupDivInputCont: {
    flexGrow: 1,
    paddingRight: "24px",
  },
  singupDivContSubmitted: {
    fontSize: "32px",
    fontWeight: "bold",
    lineHeight: "48px",
    color: "#FFF",
    width: "100%",
    maxWidth: "500px",
    height: "282px",
    display: "table",
  },
};

const styleMed = {
  headerButton1: {
    padding: "16px 32px",
    backgroundColor: "transparent",
    border: 0,
    borderRadius: 8,
    fontSize: "17px",
    fontWeight: "bold",
    color: "#FFF",
  },
  headerButton2: {
    padding: "16px 32px",
    backgroundColor: "#23CE85",
    marginLeft: "12px",
    border: 0,
    borderRadius: 8,
    fontSize: "17px",
    fontWeight: "bold",
    color: "#161C2D",
  },
  bannerFlexContText1: {
    fontSize: "36px",
    fontWeight: "bold",
    color: "#FFF",
    paddingTop: "30px",
    lineHeight: "48px",
  },
  bannerFlexContText2: {
    paddingTop: "20px",
    fontSize: "24px",
    color: "rgba(255, 255, 255, 0.75)",
    lineHeight: "36px",
    fontWeight: "400",
    paddingRight: "10px",
  },
  bannerFlexContText3: {
    marginTop: "40px",
    fontSize: "24px",
    color: "#9BFFFF",
    lineHeight: "40px",
    fontWeight: "bold",
    paddingRight: "10px",
  },
  bannerFlexContText3Arrow: {
    width: "14px",
    marginLeft: "16px",
  },
  sectionSubDivContHeader: {
    fontSize: "36px",
    fontWeight: "bold",
    color: "#161C2D",
    lineHeight: "48px",
  },
  sectionSubDivContText: {
    paddingTop: "30px",
    fontSize: "24px",
    color: "rgba(0, 0, 0, 0.75)",
    lineHeight: "36px",
    fontWeight: "400",
    paddingRight: "10px",
  },
  singupDivContText: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#48D89A",
    lineHeight: "36px",
  },
};

const styleMobile = {
  // mobile styles
  logoImg: {
    height: "40px",
    width: "136px",
    zIndex: 1,
  },

  mainDiv: {
    margin: "-8px",
    fontFamily: "sans-serif",
  },
  bannerDiv: {
    padding: "20px 20px",
    backgroundImage: "linear-gradient(90deg, #31795A 0%, #23CE85 100%)",
    backgroundSize: "cover",
  },
  ellipse: {
    position: "absolute",
    marginTop: "-20px",
    marginLeft: "-20px",
    zIndex: 0,
    height: "400px",
    width: "100%",
    background:
      "radial-gradient(ellipse at left top, rgba(0, 0, 0, 0.25) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0))",
  },
  headerButtonCont: {
    display: "inline-block",
    flexGrow: 1,
    textAlign: "right",
    padding: "6px",
    zIndex: 1,
  },
  headerButton1: {
    padding: "8px 16px",
    backgroundColor: "transparent",
    border: 0,
    borderRadius: 8,
    fontSize: "12px",
    fontWeight: "bold",
    color: "#FFF",
  },
  bannerFlex: {
    paddingTop: "30px",
    zIndex: 1,
  },
  bannerFlexCont1: {
    textAlign: "left",
    zIndex: 1,
  },
  bannerFlexContText1: {
    paddingTop: "10px",
    fontSize: "32px",
    fontWeight: "bold",
    color: "#FFF",
    lineHeight: "48px",
  },
  bannerFlexContText2: {
    paddingTop: "20px",
    fontSize: "20px",
    color: "rgba(255, 255, 255, 0.75)",
    lineHeight: "32px",
    fontWeight: "400",
  },
  bannerFlexContText3: {
    marginTop: "40px",
    fontSize: "21px",
    color: "#9BFFFF",
    lineHeight: "50px",
    fontWeight: "bold",
    paddingRight: "10px",
  },
  bannerFlexContText3Arrow: {
    width: "14px",
    marginLeft: "12px",
  },
  bannerFlexCont2: {
    paddingBottom: "40px",
  },

  sectionDiv: {
    padding: "20px",
  },
  sectionSubDiv: {
    paddingTop: "50px",
  },
  sectionSubDivReversed: {
    paddingTop: "20px",
    flexDirection: "row-reverse",
  },
  sectionSubDivCont1: {
    textAlign: "left",
    display: "table",
  },
  sectionSubDivContCell: {
    display: "table-cell",
    verticalAlign: "middle",
  },
  sectionSubDivContHeader: {
    fontSize: "32px",
    fontWeight: "bold",
    color: "#161C2D",
    lineHeight: "48px",
  },
  sectionSubDivContText: {
    paddingTop: "20px",
    fontSize: "20px",
    color: "rgba(0, 0, 0, 0.75)",
    lineHeight: "32px",
    fontWeight: "400",
    paddingRight: "10px",
    paddingBottom: "10px",
  },
  sectionSubDivImage: {
    flexGrow: 1,
    paddingBottom: "20px",
  },

  signupDiv: {
    padding: "20px",
    backgroundColor: "#EFF3FB",
  },

  signupDivCont: {
    padding: "20px",
    backgroundColor: "#161C2D",
    borderRadius: "16px",
  },
  signupDivContTable: {
    textAlign: "left",
    display: "table",
  },
  signupDivContCell: {
    display: "table-cell",
    verticalAlign: "middle",
  },
  singupDivContText: {
    fontSize: "32px",
    fontWeight: "bold",
    color: "#48D89A",
    lineHeight: "48px",
  },
  signupDivContTable2: {
    width: "100%",
    paddingTop: "20px",
    display: "table",
    paddingBottom: "20px",
  },
  signupDivButton: {
    width: "100%",
    padding: "16px 20px",
    backgroundColor: "#23CE85",
    border: 0,
    borderRadius: "8px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#111928",
    marginTop: "20px",
  },

  signupDivButton2: {
    padding: "16px 32px",
    backgroundColor: "#FFF",
    border: 0,
    borderRadius: "8px",
    fontSize: "17px",
    fontWeight: "bold",
    color: "#111928",
    marginTop: "40px",
  },
  signupDivFlexReversed: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  signupDivInput: {
    height: "40px",
    borderRadius: "8px",
    border: "1px",
    width: "100%",
    paddingLeft: "16px",
    paddingTop: "0px",
    paddingBottom: "0px",
    marginTop: "10px",
    marginBottom: "20px",
  },
  signupDivInputError: {
    height: "40px",
    borderRadius: "8px",
    border: "1px solid red",
    width: "100%",
    paddingLeft: "16px",
    paddingTop: "0px",
    paddingBottom: "0px",
    marginTop: "10px",
    marginBottom: "20px",
    backgroundColor: "#ffdddd",
  },
  signupDivInputLabel: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "#FFF",
  },
  signupDivInputCont: {
    flexGrow: 1,
    paddingRight: "24px",
  },
  singupDivContSubmitted: {
    fontSize: "32px",
    fontWeight: "bold",
    lineHeight: "48px",
    color: "#FFF",
    width: "100%",
    maxWidth: "500px",
    height: "282px",
    display: "table",
  },
};

const LandingProvider = () => {
  const formRef = useRef(null);
  const imgRef = useRef(null);
  const imgRef1 = useRef(null);
  const imgRef2 = useRef(null);
  const imgRef3 = useRef(null);
  const [showImg, setShowImg] = useState(false);
  const executeScroll = () => formRef.current.scrollIntoView();

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const handleResize = () => {
    setWindowDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
    if (isMobile) {
      setTimeout(() => {
        imgRef.current.src = headerimage1;
        imgRef1.current.src = sectionimage1;
        imgRef2.current.src = sectionimage2;
        imgRef3.current.src = sectionimage3;
        setShowImg(true);
      }, 100);
    } else {
      setTimeout(() => {
        imgRef.current.src = headerimage1hq;
        imgRef1.current.src = sectionimage1hq;
        imgRef2.current.src = sectionimage2hq;
        imgRef3.current.src = sectionimage3hq;
        setShowImg(true);
      }, 100);
    }
  }, []);

  // form variables
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");

  const [errorName, setErrorName] = useState(false);
  const [errorCompany, setErrorCompany] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCompanyChange = (event) => {
    setCompany(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  function isEmail(val) {
    // let regEmail = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
    var regEmail =
      /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i;
    if (!regEmail.test(val)) {
      return false;
    }
    return true;
  }

  async function reloadForm() {
    setFormError(false);
    setFormSubmitted(false);
  }

  const register = async (e) => {
    e.preventDefault();
    setLoading(true);
    var error = false;
    if (name === "") {
      error = true;
      setErrorName(true);
    } else {
      setErrorName(false);
    }

    if (company === "") {
      error = true;
      setErrorCompany(true);
    } else {
      setErrorCompany(false);
    }

    if (email === "") {
      error = true;
      setErrorEmail(true);
    } else if (!isEmail(email)) {
      error = true;
      setErrorEmail(true);
    } else {
      setErrorEmail(false);
    }

    if (error) {
      setLoading(false);
      return;
    }

    var data = {
      email: email,
      company: company,
      name: name,
    };

    try {
      const url = BASE_URL + "/register/provider";
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-Moongig-Secret": API_SECRET,
        },
      };
      var result = await axios.post(url, data, config);

      if (result.status !== 200) {
        const error = await result.json();
        console.error("Got error saving data", error);
        setFormError(true);
      } else {
        if (window.fbq) {
          window.fbq("track", "Lead");
          console.log('Facebook Pixel "Lead" event triggered');
        } else {
          console.log("Facebook Pixel not loaded");
        }
        setFormError(false);
        console.log("Data saved to Notion!");
      }
    } catch (error) {
      setFormError(true);
      console.error(error);
    }
    setFormSubmitted(true);
    setLoading(false);
  };
  // async function register() {
  //   setLoading(true);
  //   var error = false;
  //   if (name === "") {
  //     error = true;
  //     setErrorName(true);
  //   } else {
  //     setErrorName(false);
  //   }

  //   if (company === "") {
  //     error = true;
  //     setErrorCompany(true);
  //   } else {
  //     setErrorCompany(false);
  //   }

  //   if (email === "") {
  //     error = true;
  //     setErrorEmail(true);
  //   } else if (!isEmail(email)) {
  //     error = true;
  //     setErrorEmail(true);
  //   } else {
  //     setErrorEmail(false);
  //   }

  //   if (error) {
  //     setLoading(false);
  //     return;
  //   }

  //   var data = {
  //     email: email,
  //     company: company,
  //     name: name,
  //   };

  //   try {
  //     const url = BASE_URL + "/register/provider";
  //     const config = {
  //       headers: {
  //         "Content-Type": "application/json",
  //         "X-Moongig-Secret": API_SECRET,
  //       },
  //     };
  //     var result = await axios.post(url, data, config);

  //     if (result.status !== 200) {
  //       const error = await result.json();
  //       console.error("Got error saving data", error);
  //       setFormError(true);
  //     } else {
  //       if (window.fbq) {
  //         window.fbq("track", "Lead");
  //         console.log('Facebook Pixel "Lead" event triggered');
  //       } else {
  //         console.log("Facebook Pixel not loaded");
  //       }
  //       setFormError(false);
  //       console.log("Data saved to Notion!");
  //     }
  //   } catch (error) {
  //     setFormError(true);
  //     console.error(error);
  //   }
  //   setFormSubmitted(true);
  //   setLoading(false);
  // }

  var stylePro = styleXL;
  if (isMobile) {
    stylePro = styleMobile;
  }

  return (
    <div style={stylePro.mainDiv}>
      {/* helmet */}
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <title>
          Moongig - Find qualified healthcare professionals to swiftly fill your
          vacancies
        </title>
        <meta
          name="title"
          content="Find qualified healthcare professionals to swiftly fill your vacancies"
        />
        <meta
          name="description"
          content="Boost your business growth by leveraging the largest network of healthcare professionals seeking flexible work options."
        />
        <meta
          name="keywords"
          content="moonlighting opportunities, rest nurses, relievers, part-time healthcare jobs, flexible medical positions, freelance healthcare work, temporary medical jobs, evening healthcare shifts, weekend medical positions, healthcare shifts work, medical professionals network, on-demand healthcare job"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="Moongig - Find qualified healthcare professionals to swiftly fill your vacancies"
        />
        <meta property="og:site_name" content="Moongig" />
        <meta property="og:url" content="www.moongig.xyz/provider" />
        <meta
          property="og:description"
          content="Boost your business growth by leveraging the largest network of healthcare professionals seeking flexible work options."
        />
      </Helmet>
      {/* banner div */}
      <div style={stylePro.bannerDiv} loading="eager">
        <div style={{ display: "flex" }}>
          <div style={stylePro.ellipse}></div>
          <a href="/" style={{ zIndex: 1 }}>
            <img src={logo} alt="logo" style={stylePro.logoImg} />
          </a>
          {isMobile ? (
            <div style={stylePro.headerButtonCont}>
              <a href="/">
                <button style={stylePro.headerButton1}>
                  I'm a medical professional
                </button>
              </a>
            </div>
          ) : (
            <div style={stylePro.headerButtonCont}>
              <span
                style={
                  windowDimensions.width > 1366
                    ? stylePro.headerButton1
                    : styleMed.headerButton1
                }
              >
                <a style={{ textDecoration: "none" }} href="/">
                  I'm a medical professional
                </a>
              </span>
              <button
                style={
                  windowDimensions.width > 1366
                    ? stylePro.headerButton2
                    : styleMed.headerButton2
                }
                onClick={executeScroll}
              >
                Register now
              </button>
            </div>
          )}
        </div>
        <div style={stylePro.bannerFlex}>
          <div style={stylePro.bannerFlexCont1}>
            <div
              style={
                isMobile || windowDimensions.width > 1366
                  ? stylePro.bannerFlexContText1
                  : styleMed.bannerFlexContText1
              }
            >
              Find qualified healthcare professionals to swiftly fill your
              vacancies
            </div>
            <div
              style={
                isMobile || windowDimensions.width > 1366
                  ? stylePro.bannerFlexContText2
                  : styleMed.bannerFlexContText2
              }
            >
              Boost your business growth by leveraging the largest network of
              healthcare professionals seeking flexible work options.
            </div>
            <div
              style={
                isMobile || windowDimensions.width > 1366
                  ? stylePro.bannerFlexContText3
                  : styleMed.bannerFlexContText3
              }
              onClick={executeScroll}
            >
              <button
                style={{
                  backgroundColor: "transparent",
                  outline: "none",
                  border: 0,
                  color: "inherit",
                  fontSize: "inherit",
                  lineHeight: "inherit",
                  fontWeight: "inherit",
                  padding: 0,
                }}
              >
                <div style={{ display: "flex" }}>
                  Register for a demo{" "}
                  <img
                    src={arrowright}
                    alt="arrow-right"
                    style={
                      isMobile || windowDimensions.width > 1366
                        ? stylePro.bannerFlexContText3Arrow
                        : styleMed.bannerFlexContText3Arrow
                    }
                  />
                </div>
              </button>
            </div>
          </div>
          <div style={stylePro.bannerFlexCont2}>
            <img
              ref={imgRef}
              alt="header-image1"
              style={{
                width: "100%",
                height: "auto",
                display: showImg ? "" : "none",
              }}
            />
          </div>
        </div>
      </div>
      {/* section divs */}
      <div style={stylePro.sectionDiv}>
        {/* section 1 */}
        <div style={stylePro.sectionSubDiv}>
          <div style={stylePro.sectionSubDivContTable}>
            <div style={stylePro.sectionSubDivContCell}>
              <div
                style={
                  isMobile || windowDimensions.width > 1366
                    ? stylePro.sectionSubDivContHeader
                    : styleMed.sectionSubDivContHeader
                }
              >
                Tap our vast pool of verified medical professionals
              </div>
              <div
                style={
                  isMobile || windowDimensions.width > 1366
                    ? stylePro.sectionSubDivContText
                    : styleMed.sectionSubDivContText
                }
              >
                We rigorously vet and validate all applicants, ensuring access
                to only the most qualified medical professionals for your
                business needs.
              </div>
            </div>
          </div>
          <div style={stylePro.sectionSubDivImage}>
            <img
              ref={imgRef1}
              alt="section-image1"
              style={{
                width: "100%",
                height: "auto",
                display: showImg ? "" : "none",
              }}
            />
          </div>
        </div>
        {/* section 2 */}
        <div style={stylePro.sectionSubDivReversed}>
          <div style={stylePro.sectionSubDivContTable}>
            <div style={stylePro.sectionSubDivContCell}>
              <div
                style={
                  isMobile || windowDimensions.width > 1366
                    ? stylePro.sectionSubDivContHeader
                    : styleMed.sectionSubDivContHeader
                }
              >
                Reduce your operational overhead and admin tasks to manage
                moonlighters
              </div>
              <div
                style={
                  isMobile || windowDimensions.width > 1366
                    ? stylePro.sectionSubDivContText
                    : styleMed.sectionSubDivContText
                }
              >
                You recruit your flexible workforce through our platform, and we
                handle scheduling, payouts and reporting.
              </div>
            </div>
          </div>
          <div style={stylePro.sectionSubDivImage}>
            <img
              ref={imgRef2}
              alt="section-image2"
              style={{
                width: "100%",
                height: "auto",
                display: showImg ? "" : "none",
              }}
            />
          </div>
        </div>
        {/* section 3 */}
        <div style={stylePro.sectionSubDiv}>
          <div style={stylePro.sectionSubDivContTable}>
            <div style={stylePro.sectionSubDivContCell}>
              <div
                style={
                  isMobile || windowDimensions.width > 1366
                    ? stylePro.sectionSubDivContHeader
                    : styleMed.sectionSubDivContHeader
                }
              >
                Build a pipeline of medical professionals to augment your
                workforce on demand
              </div>
              <div
                style={
                  isMobile || windowDimensions.width > 1366
                    ? stylePro.sectionSubDivContText
                    : styleMed.sectionSubDivContText
                }
              >
                Integrate a flexible workforce to support your business's
                seasonal needs without high overhead costs.
              </div>
            </div>
          </div>
          <div style={stylePro.sectionSubDivImage}>
            <img
              ref={imgRef3}
              alt="section-image3"
              style={{
                width: "100%",
                height: "auto",
                display: showImg ? "" : "none",
              }}
            />
          </div>
        </div>
      </div>
      {/* signup div */}
      <div style={stylePro.signupDiv}>
        <div ref={formRef} style={stylePro.signupDivCont}>
          <div style={stylePro.signupDivCont1}>
            <div
              style={
                isMobile || windowDimensions.width > 1366
                  ? stylePro.singupDivContText
                  : styleMed.singupDivContText
              }
            >
              Register your contact information, and we will reach out to
              schedule a product walk-through.
            </div>
          </div>
          <div style={stylePro.signupDivContTable2}>
            <div style={stylePro.signupDivContCell}>
              {!formSubmitted ? (
                <>
                  <div style={stylePro.signupDivInputCont}>
                    <div style={stylePro.signupDivInputLabel}>
                      <label for="name">Name</label>
                    </div>
                    <input
                      id="name"
                      style={
                        errorName
                          ? stylePro.signupDivInputError
                          : stylePro.signupDivInput
                      }
                      onChange={handleNameChange}
                      value={name}
                    />
                  </div>
                  <div style={stylePro.signupDivInputCont}>
                    <div style={stylePro.signupDivInputLabel}>
                      <label for="company">Company Name</label>
                    </div>
                    <input
                      id="company"
                      style={
                        errorCompany
                          ? stylePro.signupDivInputError
                          : stylePro.signupDivInput
                      }
                      onChange={handleCompanyChange}
                      value={company}
                    />
                  </div>
                  <div style={stylePro.signupDivInputCont}>
                    <div style={stylePro.signupDivInputLabel}>
                      <label for="email">Email Address</label>
                    </div>
                    <input
                      id="email"
                      style={
                        errorEmail
                          ? stylePro.signupDivInputError
                          : stylePro.signupDivInput
                      }
                      onChange={handleEmailChange}
                      value={email}
                    />
                  </div>
                </>
              ) : formError ? (
                <div style={stylePro.singupDivContSubmitted}>
                  <div style={{ display: "table-cell", color: "#FFF" }}>
                    Oops! We encountered an error during the registration.
                    <br />
                    While we look into this, please try again later.
                    <br />
                    <button
                      style={stylePro.signupDivButton2}
                      onClick={reloadForm}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              ) : (
                <div style={stylePro.singupDivContSubmitted}>
                  <div style={{ display: "table-cell" }}>
                    Thank you for registering your interest.
                    <br />
                    You will hear from us soon!
                  </div>
                </div>
              )}
              {formSubmitted ? (
                <div style={{ height: "70px" }}></div>
              ) : loading ? (
                <button
                  style={stylePro.signupDivButton}
                  onClick={register}
                  disabled
                >
                  Register
                </button>
              ) : (
                <button style={stylePro.signupDivButton} onClick={register}>
                  Register
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* contact div */}
      {isMobile ? <FooterMobile /> : <FooterDesktop />}
    </div>
  );
};

export default LandingProvider;
